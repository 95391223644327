<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam" :configurable="isCard">
            <p slot="elList">
                <template >
                    <el-card class="box-card" v-for="(item) in dataSource.Result" :key="item.Id">
                        <div slot="header" class="clearfix">
                            <span>{{item.DictionaryName}}</span>
                             <el-button style="float: right; padding: 3px 0" type="text" @click="rowClick(item)">点击配置</el-button>
                        </div>
                        <div>
                            <span>{{item.Remark}}</span>
                        </div>
                    </el-card>
                </template>
            </p>
        </fixed-list>
    </div>
</template>
<script>
    export default {
        mounted() {
            this.Event.$on("reloadPageList", () => this.reloadPageList());
            this.Event.$on("onAdd", () => this.add());
            this.initialize();
        },
        data() {
            return {
                multipleSelection: [],
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0
                },
                isCard:false
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
                isButtonlDisplay: false,
            },
            option: {}
        },
        methods: {
            reloadPageList: function () {
                this.initialize();
            },
            initialize() {
                this.onPageChange(this.queryParam);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.$emit("onCheckRow", this.multipleSelection);
            },
            onPageChange(param) {
                var _this = this;
                this.queryParam = param;
                this.$ajax.query("omsapi/sysindustry/getlist", "post", param, data => {
                    _this.dataSource = data;
                });
            },
            onDataSourceChange(ds) {
                var _this = this;

                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });
            },
            rowClick(row) {
                this.onChangeEditDataSource(row.DictionaryId);
            },
            add() {
                this.$ajax.send("omsapi/sysconfig/getemptysysconfig", "get", {}, (data) => {
                    this.onChangeEditDataSource(data.Result);
                });
            },
            del() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择要删除的配置项.`, "error");
                    return false;
                }
                var This = this;
                this.Utils.confirm({
                    title: "是否确认删除配置项?",
                    content: "删除后数据不可恢复,该配置项将不可使用,是否确认删除?"
                }, () => {
                    this.$ajax.send("omsapi/sysconfig/delete", "post", this.multipleSelection, (data) => {
                        This.onPageChange(This.queryParam);
                        This.Utils.messageBox("配置项删除成功.", "success");
                    });
                }, () => {
                    This.Utils.messageBox("已取消删除.", "info");
                });
            },
            onChangeEditDataSource(data) {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", data);
            },
        }
        //components: {
        //}
    }
</script>
<style>
.box-card {
    float: left;
    width: 320px;
    margin: 10px 10px;
    padding: 20px;
  }
</style>